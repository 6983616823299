import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Нэвтрэх",
  "description": null,
  "author": "OTC help",
  "category": "account-management",
  "date": "2022-03-28T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 2
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Нэвтрэх`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Нүүр хуудасны баруун дээд булан дахь `}<strong parentName="p">{`[Login]`}</strong>{` товчийг дарж нэвтрэх хэсэгт очно.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`МҮЦАЭХ-ны Бүртгэлийн хорооноос олгосон и-мэйл хаяг болон нууц үгийг оруулан `}<strong parentName="p">{`[Sign in]`}</strong>{` товч дарж нэвтэрнэ.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      